const initialState = {
    constituentDetails: [],
    currentConstituent: null,
    currentOrganisation: null,
    currentRecreationSport: null
}

const upravljanjeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CURRENT_CONSTITUENT':
            return {
                ...state,
                currentConstituent: action.currentConstituent,
            }
        case 'CURRENT_ORGANISATION':
            return {
                ...state,
                currentOrganisation: action.currentOrganisation,
            }
        case 'CURRENT_RECREATION_SPORT': 
        return {
            ...state,
            currentRecreationSport: action.currentRecreationSport
        }
        default:
            return { ...state }
    }
}
export default upravljanjeReducer
