// **  Initial State
const initialState = {
    row: {},
    newsCategories: [],
    eventCategories: [],
    documentRow: []
  }
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'EVENT_DETAILS':
        return {
          ...state,
          row: action.row,
        }
        case 'NEWS_CATEGORIES':
          return {
            ...state,
            newsCategories: action.newsCategories
          }
          case 'EVENT_CATEGORIES':
            return {
              ...state,
              eventCategories: action.eventCategories
            }
            case 'DOCUMENT_ROW':
              return {
                ...state,
                documentRow: action.documentRow
              }
      default:
        return state
    }
  }
  
  export default authReducer
  