const initialState = {
    odabranaObjava: null,

}

const objaveReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ODABRANA_OBJAVA':
            return {
                ...state,
                odabranaObjava: action.odabranaObjava,
            }
        default:
            return {...state}
    }
}
export default objaveReducer
