// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import galerija from './galerija'
import natjecanja from './natjecanja'
import rekreacije from './rekreacije'
import upravljanje from './upravljanje'
import objave from './objave'
import cms from './cms'
import register from './register'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  galerija,
  natjecanja,
  rekreacije,
  upravljanje,
  objave,
  cms,
  register
})

export default rootReducer
