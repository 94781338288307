const initialState = {
    recreationsStep1: null,
    recreationsStep2: null,
    recreationsStep3: null,
    recreationsStep4: null,
    odabranaRekreacijaZaPrijavu: null
}

const rekreacijeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RECREATIONS_STEP1':
            return {
                ...state,
                recreationsStep1: action.recreationsStep1,
            }
        case 'RECREATIONS_STEP2':
            return {
                ...state,
                recreationsStep2: action.recreationsStep2,
            }
        case 'RECREATIONS_STEP3':
            return {
                ...state,
                recreationsStep3: action.recreationsStep3,
            }
        case 'RECREATIONS_STEP4':
            return {
                ...state,
                recreationsStep4: action.recreationsStep4,
            }
        case 'ODABRANA_REKREACIJA_ZA_PRIJAVU':
            return {
                ...state,
                odabranaRekreacijaZaPrijavu: action.odabranaRekreacijaZaPrijavu,
            }
        default:
            return {...state}
    }
}
export default rekreacijeReducer
