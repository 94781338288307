const initialState = {
    newNatjecanjeStep1Data: {},
    newNatjecanjeStep2Data: {},
    natjecanjeDetails: {},
    natjecanjeApplicationStep1: {},
    natjecanjeApplicationStep2Contacts: [],
    newTeamName: "",
    chosenTeam: null,
    registrationCompetition: null,
    natjecanjeDetailsStep1: []
}

const natjecanjaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NATJECANJE_STEP1':
            return {
                ...state,
                newNatjecanjeStep1Data: action.newNatjecanjeStep1Data,
            }
        case 'NATJECANJE_STEP2':
            return {
                ...state,
                newNatjecanjeStep2Data: action.newNatjecanjeStep2Data,
            }

        case 'NATJECANJE_DETAILS':
            return {
                ...state,
                natjecanjeDetails: action.natjecanjeDetails,
            }

        case 'NATJECANJE_APPLICATION_STEP1':
            return {
                ...state,
                natjecanjeApplicationStep1: action.natjecanjeApplicationStep1,
            }

        case 'NATJECANJE_APPLICATION_STEP2_CONTACTS':
            return {
                ...state,
                natjecanjeApplicationStep2Contacts: action.natjecanjeApplicationStep2Contacts,
            }
        case 'NEW_TEAM_NAME':
            return {
                ...state,
                newTeamName: action.newTeamName,
            }
        case 'CHOSEN_TEAM':
            return {
                ...state,
                chosenTeam: action.chosenTeam,
            }
        case 'REGISTRATION_COMPETITION':
            return {
                ...state,
                registrationCompetition: action.registrationCompetition,
            }
        case 'NATJECANJA_DETAILS_STEP1':
            return {
                ...state,
                natjecanjeDetailsStep1: action.natjecanjeDetailsStep1,
            }
        default:
            return { ...state }
    }
}
export default natjecanjaReducer
