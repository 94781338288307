const initialState = {
    registerStepOne: null,
}

const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REGISTER_STEP_ONE':
            return {
                ...state,
                registerStepOne: action.registerStepOne,
            }
        default:
            return {...state}
    }
}
export default registerReducer
