const initialState = {
    currentCompetition: "",
}

const galerijaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CURRENT_COMPETITION':
            return {
                ...state,
                currentCompetition: action.currentCompetition,
            }
        default:
            return { ...state }
    }
}
export default galerijaReducer
